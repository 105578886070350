import React, {useContext} from 'react'

import SiteContext from 'helpers/siteContext'
import { Button } from '../Button'

import * as cls from './faqAside.module.scss'

const FaqAside = () => {
  const siteData = useContext(SiteContext)
  const data = siteData.blockCTA

  if (!data) {
    return <></>
  }

  return (
    <aside className={`${cls.faq__aside} column`}>
      <h3 className={`${cls.faq__aside_title}`}>{data.title}</h3>

      {
        data.items && data.items.map((item, index) =>
          <Button key={index} href={item.url} text={item.title} />
        )
      }
    </aside>
  )
}

export default FaqAside
