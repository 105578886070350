import React from 'react'

import { Header } from 'components/Header'
import Seo from 'components/seo'
import FaqAside from 'components/FaqAside'
import Collapsible from 'components/pages/faq/Collapsible/Collapsible'

import * as styles from './faq.module.scss'

const FaqPage = ({ pageContext: { faqs, pageData } }) => {
  return (
    <>
      <Seo title={pageData.title} meta={pageData.seo} />
      <Header
        h1={pageData.header.h1}
        breadcrumbs={[{
          title: pageData.title,
        }]}
        isWide
      />

      <section>
        <div className="container columns">
          <ul className={`column is-7 ${styles.questions}`}>
            {faqs.map((question, i) =>
              <Collapsible
                key={i}
                title={question.question}
                content={question.answer}
              />
            )}
          </ul>

          <FaqAside />
        </div>
      </section>
    </>
  )
}

export default FaqPage
