import React from 'react';

// Styles
import * as cls from './button.module.scss';

// Icons
import ArrowRight from 'assets/images/arrow-right.svg';

export function Button(props) {

  const buttonFlatStyle = props.buttonFlatStyle ? `${cls.buttonFlatStyle}` : '';

  return (
    <a className={`${cls.btn} ${buttonFlatStyle}`} href={props.href}>
      {props.text}
      <ArrowRight />
    </a>
  );
}
