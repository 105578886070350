import React from 'react'

import Image from 'components/Image'
import { Button } from 'components/Button'
import Breadcrumbs from 'components/Header/components/Breadcrumbs'

import * as cls from './header.module.scss'

export function Header({ h1, h2, theses, button, buttonFlatStyle, rightBlock, breadcrumbs = [], isWide = false }) {

  const bottomIndent = (rightBlock && rightBlock.type.name === 'BlockCTA') ? 0 : '65px';

  const breadcrumbsComponent = () => {
    if (Array.isArray(breadcrumbs) && breadcrumbs.length > 0) {
      return (
        <nav className="breadcrumb" aria-label="breadcrumbs">
          <Breadcrumbs items={breadcrumbs} />
        </nav>
      )
    }
    return <></>
  }

  return (
    <section className={`${cls.intro} ${isWide ? cls.intro_mod : ''}`} style={{ paddingBottom: bottomIndent }}>
      <div className="container">
        <div className={cls.intro_inner}>
          <div className={cls.intro_content}>
            {breadcrumbsComponent()}

            <h1 className="enable-line-break">{h1}</h1>
            <h2>{h2}</h2>

            {
              theses &&
              <ul className={cls.intro_list}>
                {theses.map((item, index) =>
                  <li key={index}>
                    <Image uploadFileData={item.image} />
                    <p className="enable-line-break">{item.title}</p>
                  </li>
                )}
              </ul>
            }
            {button && <Button href={button.url} text={button.title} buttonFlatStyle={buttonFlatStyle} />}
          </div>
          {rightBlock}
          {
            theses &&
            <ul className={cls.intro_list}>
              {theses.map((item, index) =>
                <li key={index}>
                  <Image uploadFileData={item.image} />
                  {item.title}
                </li>
              )}
            </ul>
          }
        </div>
      </div>
    </section>
  )
}
