import React from 'react'

const baseUrl = process.env.GATSBY_IMAGE_BASE_URL.replace(/\/$/g, '');

const Image = ({ uploadFileData, className }) => {
  const data = uploadFileData?.data?.attributes

  if (!data) {
    return <></>
  }

  return <img
    src={`${baseUrl}${data.url}`}
    className={className}
    alt={data.alternativeText}
  />
}

export default Image
