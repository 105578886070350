import React, { useState } from 'react'
import HtmlParser from 'components/HtmlParser'

import * as styles from './collapsible.module.scss'

const Collapsible = ({ title, content, collapsed } = { collapsed: false }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed)

  const handleOpen = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <div className={`${styles.collapsible} ${isCollapsed ? styles.open : null}`}>
      <button
        className={styles.collapsible_title}
        tabIndex={0}
        onClick={handleOpen}
      >
        {title}
      </button>

      <div className={styles.collapsible_content}>
        <HtmlParser htmlString={content} />
      </div>
    </div>
  )
};

export default Collapsible
